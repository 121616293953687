//slider home
const swiper = new Swiper('.swiper', {
    // Optional parameters
    loop: true,
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
  });

//mascaras
$(".tel-mask")
    .focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, "");
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    })
    .trigger("focusout");

// ENVIO DE CONTATO E REPRESENTANTES
if ($("#formContato").length) {
    //VALIDA form
    $("#formContato").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: '#target',
                beforeSubmit: function () {
                    $("button#btn-enviar-contato").attr("disabled", true);
                    $("button#btn-enviar-contato").html("Enviando...");
                },
                complete: function (response) {
                    console.log(response.responseText + " - teste");
                    var arr = response.responseText.split("|");
                    if (arr[0] == "1") {
                        $("button#btn-enviar-contato").attr("disabled", false);
                        $("button#btn-enviar-contato").html("ENVIAR");
                        $("#formContato")[0].reset();
                        $("#formContato .input").blur();
                        $("#target").html(arr[1]);
                    } else {
                        $("#target").html(arr[1]);
                        $("button#btn-enviar-contato").attr("disabled", false);
                        $("button#btn-enviar-contato").html("ENVIAR");
                    }
                },
            });
        },
    });
}

if ($("#section-fashion").length) {
    const videoElement = document.querySelector("video");
    const playPauseButton = document.querySelector("button");

    playPauseButton.addEventListener("click", () => {
        playPauseButton.classList.toggle("playing");
        if (playPauseButton.classList.contains("playing")) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    });

    videoElement.addEventListener("ended", () => {
        playPauseButton.classList.remove("playing");
    });
}

$(document).on("click", ".menuOpen", function (e) {
    if ($(".navMobile").hasClass("open")) {
        $(".navMobile,.hamburguer").removeClass("open");
        $(".btnSubmenu").parent().removeClass("active");
    } else {
        $(".navMobile,.hamburguer").addClass("open");
    }
});

$(document).on("click", ".btnSubmenu", function (e) {
    var elem = $(this).parent();
    if (!elem.hasClass("active")) {
        $(".btnSubmenu").parent().removeClass("active");
        elem.addClass("active");
    } else {
        elem.removeClass("active");
    }
});
